import {getHour} from '../helpers'
import Switch from "react-switch";
import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';

export default function TraningCard(props){
    const x=props.trainingSession;
    const a=props.athlete;
    const auth0 = useAuth0();
    const {user,isAuthenticated, isLoading,  loginWithRedirect, logout,getAccessTokenSilently}=auth0;   
    const [confirmedAthletesVisible, setConfirmedAthletesVisible ]=useState(false);
 return (
    <React.Fragment>
    {x.Status === 'OpenConfirmations' &&
    <div className="trainingCard"> 
                            <h3 className="location">{x.TrainingLocationText} {getHour(x.TrainingStart)}</h3>       
                            <div className="eticheta">(experimental) Confirmati:{x.Confirmati} { confirmedAthletesVisible?x.ConfirmedAthletes:''}     
                            </div>
                            { x.Confirmati>0 &&                                                                                                           
                            <a className='suplimentar' onClick={()=>setConfirmedAthletesVisible(!confirmedAthletesVisible)}>
                              { confirmedAthletesVisible?'(ascunde numele)':'(afiseaza numele)'}
                            </a>
                            }


                            <p className="info">Antrenor:{x.TrainerName} </p>
                            <a className="suplimentar" href={x.TrainingLocationLink} target="_blank">[indicatie locatie]</a>
                            <div className="eticheta">Confirma prezenta (pana la {getHour(x.ConfirmationDeadline)}) </div>
                            <h2 className="ciclistName">{a.Surname} {a.Name} </h2> 
                            {a.paymenttype==='sedinta'?<p className="info">Cost:{x.Cost} lei </p>:<p className="info">{a.paymenttype}</p>}
                            <div>
                            absent
                            <Switch 
                            checked={Boolean(a.ConfirmationReceived)}
                            onChange={(ev)=>
                              getAccessTokenSilently().then(token=>
                              props.saveConfirmation({athleteId:a.AthleteId,trainingSessionId:x.TrainingSessionId, confirmationValue:!a.ConfirmationReceived},token))}
                            onColor="#FFC7E0"
                            onHandleColor="#930041"
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                            className="react-switch switch"
                            id="material-switch"
                            />
                            prezent
                            </div>                            
                          </div>
    }


    {/* 'Past','Draft','Posted','OpenConfirmations','AssigningTrainers','Presence','TrainingStarted','Finished','Canceled' */}

    {x.Status === 'AssigningTrainers' &&
    <div className="trainingCard"> 
      <h3 className="location">{x.TrainingLocationText} {getHour(x.TrainingStart)}</h3>                            
      <p className="info">Antrenor:{x.TrainerName} </p>
      <a className="suplimentar" href={x.TrainingLocationLink} target="_blank">[indicatie locatie]</a>
      <h2 className="ciclistName">{a.Surname} {a.Name} </h2> 
      <p>
             {a.ConfirmationReceived ? 'confirmat :)' : 'neconfirmat :(' }
      </p>                            
      <p className="info">{x.Confirmati} sportivi au confirmat prezenta.</p>
    </div> 
    }

   {x.Status === 'Presence' &&
    <div className="trainingCard"> 
      <h3 className="location">{x.TrainingLocationText} {getHour(x.TrainingStart)}</h3>                            
      <p className="info">Antrenor:{x.TrainerName} </p>
      <a className="suplimentar" href={x.TrainingLocationLink} target="_blank">[indicatie locatie]</a>
      <h2 className="ciclistName">{a.Surname} {a.Name} </h2> 
      <p>
             {a.ConfirmationReceived ? 'confirmat :)' : 'neconfirmat :(' }
      </p>                            
      <p className="info">{x.Confirmati} sportivi au confirmat prezenta.</p>
      <p className="info">Antrenorii de azi sunt: {x.Antrenori}</p>
    </div> 
    }

   {x.Status === 'TrainingStarted' &&
    <div className="trainingCard"> 
      <h3 className="location">{x.TrainingLocationText} {getHour(x.TrainingStart)}</h3>                            
      <p className="info">Antrenor:{x.TrainerName} </p>
      <a className="suplimentar" href={x.TrainingLocationLink} target="_blank">[indicatie locatie]</a>
      <h2 className="ciclistName">{a.Surname} {a.Name} </h2> 
      <p>
             {a.ConfirmationReceived ? 'confirmat :)' : 'neconfirmat :(' }
      </p>                            
      <p className="info">{x.Confirmati} sportivi au confirmat prezenta.</p>
      <p className="info">Antrenorii de azi sunt: {x.Antrenori}</p>
    </div> 
    }

    {x.Status === 'Finished' &&
    <div className="trainingCard"> 
      <h3 className="location">{x.TrainingLocationText} {getHour(x.TrainingStart)}</h3>                            
      <h3 className="location">S-A INCHEIAT!</h3>                            
      <p className="info">Antrenor:{x.TrainerName} </p>

      <h2 className="ciclistName">{a.Surname} {a.Name} </h2> 
      <p>
             {a.ConfirmationReceived ? 'confirmat :)' : 'neconfirmat :(' }
      </p>                            
      <p className="info">{x.Prezenti} sportivi au fost prezenti.</p>
      <p className="info">Antrenorii de azi au fost: {x.Antrenori}</p>
    </div> 
    }

    {x.Status === 'Canceled' &&
    <div className="trainingCard"> 
      <h3 className="location">{x.TrainingLocationText} {getHour(x.TrainingStart)}</h3>                            
      <h3 className="location">S-A ANULAT!</h3>                            
      <p className="info">Antrenor:{x.TrainerName} </p>

      <h2 className="ciclistName">{a.Surname} {a.Name} </h2> 
      <p>
             {a.ConfirmationReceived ? 'confirmat :)' : 'neconfirmat :(' }
      </p>                            
      <p className="info">{x.Confirmati} sportivi confirmasera.</p>
    </div> 
    }

  </React.Fragment>

 )
}