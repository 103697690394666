import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect, useState } from 'react';
import Menu from './menu';
import TrainingCard from './training-card';
import { connect } from 'react-redux'
import { fetchTrainings, saveConfirmation, fetchProfile } from '../redux'
const monthNames = ["IANUARIE", "FEBRUARIE", "MARTIE", "APRILIE", "MAI", "IUNIE",
  "IULIE", "AUGUST", "SEPTEMBRIE", "OCTOMBRIE", "NOIEMBRIE", "DECEMBRIE"
];

const dayNames = ["Duminica", "Luni", "Marti", "Miercuri", "Joi", "Vineri", "Sambata"];


const getDates = (trainings)=>{  
  const k =  trainings.reduce((p,c)=>{
    
    const trainingDate=(new Date(c.TrainingStart));
    const trainingDateS =              
      dayNames[trainingDate.getDay()]+', '+
      trainingDate.getDate()+' '+
      monthNames[trainingDate.getMonth()]
      
    if (!p.find(x=>x.date === trainingDateS)){
        return [...p,{date:trainingDateS,ts:c.TrainingStart}];
    } else {
      return p;
    } 
  },[]);

  k.sort((a,b)=> a.ts.localeCompare(b.ts));

  
  return k;
}

const getTrainingOnDate=(trainings, date)=>{
  return trainings.reduce((p,c)=>{
    const trainingDate=(new Date(c.TrainingStart));
    const trainingDateS =              
      dayNames[trainingDate.getDay()]+', '+
      trainingDate.getDate()+' '+
      monthNames[trainingDate.getMonth()]

    if (date===trainingDateS){
        return [...p,c];
    } else {
      return p;
    } 
  },[]);
}



function Today(props) {

    const auth0 = useAuth0();
    const {user,isAuthenticated, isLoading,  loginWithRedirect, logout,getAccessTokenSilently}=auth0;    
    //const [confirmedAthletesVisible, setConfirmedAthletesVisible ]=useState(false);

    useEffect(()=>{  
      if (!isAuthenticated || !Boolean(user) || !user.email_verified ) return;

       if (isAuthenticated && Boolean(user)) {
          getAccessTokenSilently()
          .then(token => {
              props.fetchProfile(user.email, token);              
              props.fetchTrainings(token);
          }
          ) ;           
       }
    },[isAuthenticated, user])

    //if (!isAuthenticated || !Boolean(user) || !user.email_verified ) return 'Not autenticated ...'
    return (
        <>
          {(isAuthenticated && user.email_verified) &&
          <>
          {(!props.trainingsData.fetched || props.trainingsData.loading || !props.profileData.fetched || props.profileData.loading  ) && 'Loading...'}
          {(!props.trainingsData.fetched && props.trainingsData.loading_error!='') && <>
            <p>A aparut o eroare la incarcarea antrenamentelor</p>
            <p>{props.trainingsData.loading_error}</p>
            </>
          }

          {(!props.profileData.fetched && props.profileData.loading_error!='') && <>
            <p>A aparut o eroare la incarcarea profilului utilizator</p>
            <p>{props.profileData.loading_error}</p>
            </>
          }

          {(props.trainingsData.saving_error!='') && <>
            <p>A aparut o eroare la transmiterea confirmarii.</p>
            <p>{props.trainingsData.saving_error}</p>
            <p>Incercati refresh-ul paginii si apoi reluati</p>
            </>
          }
                   
          {(props.trainingsData.fetched && !props.trainingsData.loading && props.trainingsData.loading_error=='' && props.trainingsData.trainings.length>0 &&
            props.profileData.fetched && !props.profileData.loading && props.profileData.loading_error==''
            ) &&
          <>                        
                    
              {getDates(props.trainingsData.trainings).map(d=>
              <React.Fragment key={d.date}>
              <h1>             
              {d.date}
              </h1>              
                {getTrainingOnDate(props.trainingsData.trainings,d.date).map(x=>(
                    <React.Fragment key={x.TrainingSessionId}>                                     
                        <section id="ciclisti">    
                            {/* <div className="eticheta left-text">Confirma prezenta (pana la {getHour(x.ConfirmationDeadline)}) </div> */}
                            {x.athletes.map(a=><TrainingCard key={a.AthleteId}
                            athlete={a} 
                            trainingSession={x}
                            saveConfirmation={props.saveConfirmation}
                            />)}    
                        </section>

                      
                    </React.Fragment>
                ))}
              </React.Fragment>              
              )}

            <div className="separator"/>
          </>    
          }
          </>
          }
          
          <Menu/>  
        </>
    );
}

const mapStateToProps = state => {
    return {
      trainingsData: state.trainings,      
      profileData: state.profile
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      fetchTrainings: (token) => dispatch(fetchTrainings(token)),
      saveConfirmation: (confirmation,token)=> dispatch(saveConfirmation(confirmation, token)),
      fetchProfile: (email,token)=>dispatch(fetchProfile(email,token))
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Today)

