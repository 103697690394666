import React from 'react';


function LegalTerms(props) {
    return (
        <div style={{ textAlignLast: 'left', textIndent: 0, textAlign: 'left' }}>
            <h1> Termene si Condiții </h1>
            <h2>Aceptarea acestor termene</h2>
            <p>Accesul dvs. şi utilizarea acestui website, a materialelor si informatiilor prezentate in cadrul său, se supune acestor Termene şi Condiţii. Nu veţi folosi acest website în scopuri ilegale sau interzise prin Termenele şi Condiţiile care urmează.</p>

            <p>IN CAZUL IN CARE UTILIZATORUL NU DORESTE SA ACCEPTE PREZENTELE TERMENE SI CONDITII, INCLUSIV MODIFICARILE/COMPLETARILE ACESTORA, TREBUIE SA INCETEZE SA MAI FOLOSEASCA SITEUL.</p>

            <p>
                Acest site aparține Asociației Club Sportiv MTB Academy,<br />
                Cu sediul in Bucuresti, sos. Iancului nr 27A, BL 105D/ AP 38<br />
                Cod de Inregistrare fiscala: 34299340
            </p>

            <p>In lipsa unor precizari specifice pentru un continut anume (imagine, text sau alt continut media), toate drepturile asupra continutului afisat pe acest site apartine Asociației Club Sportiv MTB Academy. si este interzisa reproducerea, preluarea, distribuirea sau utilizarea oricarei informatii sau material fara acordul prealabil, in scris, din partea Asociației Club Sportiv MTB Academy.</p>
            <p>Toate drepturile de utilizare asupra informatiilor si materialelor disponibile apartin Asociației Club Sportiv MTB Academy.</p>
            <p>Utilizatorul acestui site este pe deplin raspunzator de respectarea tuturor legilor si actelor legislative/normative in vigoare, si se obliga sa nu utilizeze nici un fel de sistem/aplicatie sau procedura care ar afecta functionarea normala a site-ului nostru, sau de a obtine informatii la care nu are access in mod normal. </p>

            <h2>Descrierea serviciului</h2>
            <p>Site-ul
                app.mtbacademy.ro
                oferă acces la informații despre activitățile Asociației Club Sportiv MTB Academy, precum și posibilitatea de înscriere la înscriere la activitățile de ciclism ale asociației. Conținutul acestui website nu poate fi considerat ca recomandare și nu ar trebui considerat ca baza pentru luarea de decizii.
            </p>
            <h2>Acces si înregistrare</h2>
            <p>Pentru a putea beneficia de serviciu, utilizatorul are nevoie de un calculator personal sau dispozitiv de tip smart-phone/tablet, o conexiune stabila la Internet si de un browser web instalat in dispozitivul prin intermediul căreia va fi accesat unul din serverele web menționate in descrierea serviciului, de unde va fi accesat serviciul in mod individual, unde este necesar un username și o parolă sau o alta cale de identificare a utilizatorului(google)
            </p>
            <h2>Modificări ale website-ului, software-ului şi ale serviciilor</h2>
            <p>Asociației Club Sportiv MTB Academy îşi rezervă dreptul de a:
                <ul><li>1.	modifica sau şterge (temporar sau permanent) website-ul sau orice parte a acestuia fără a anunţa, iar dvs. acceptaţi faptul că Asociația Club Sportiv MTB Academy nu este responsabilă pentru asemenea modificări sau ştergeri.
                </li><li>2.	modifica, şterge sau întrerupe orice software, serviciu sau promoţie (inclusiv dar fără a se limita la orice prevederi, părţi, licenţe, preţuri) aşa cum sunt promovate pe acest website în orice moment, fără a anunţa, iar dvs. acceptaţi faptul că Asociația Club Sportiv MTB Academy nu este responsabilă pentru asemenea modificări sau ştergeri.
                    </li><li>3.	modifica sau întrerupe orice voucher promoţional de reducere sau cod de cupon de reducere în orice moment cu anunţ prealabil, iar dvs. acceptaţi faptul că  Asociația Club Sportiv MTB Academy nu este responsabilă pentru asemenea modificări sau ştergeri.
                    </li><li>4.	modifica această înțelegere în orice moment, iar continuarea utilizării website-ului de către dvs. după aceste schimbări se va supune acceptării acestor modificări de către dvs.
                    </li></ul></p>
            <p>Asociația Club Sportiv MTB nu isi asuma răspunderea pentru nici o pierdere de date sau informatii rezultate din intarzieri, comenzi neonorate sau care nu ajung la destinatar, cauzate de evenimente care nu sunt sub controlul  Asociația Club Sportiv MTB Academy, al furnizorilor săi sau prin erori de omisie din partea utilizatorului. Asociația Club Sportiv MTB si furnizorii sai exclud orice sugestie sau garantie ca:
                <ul> <li>serviciul va fi neintrerupt sau fara erori;</li>
                    <li>eventualele defecte vor fi corectate;</li></ul></p>
            <p>Serviciul este disponibil 24h/zi, exceptand perioadele in care se efectueaza lucrari de mentenanta sau de reparatii. Accesarea serviciului poate fi limitata in intervalul orar de utilizare intensa datorita limitarii procesarii datelor de catre sistemele informatice.
            </p><h2>Linkuri către website-urile terţilor</h2>
            <p>Website-ul poate include linkuri către website-urile terţilor, care sunt controlate şi conduse de alţii. Orice link spre un alt website nu este o recomandare a acelui website, iar dvs. luaţi la cunoştinţă acest fapt şi sunteţi conştienţi de faptul că nu ne asumăm responsabilitatea pentru conţinutul sau pentru disponibilitatea acestor website-uri.
            </p>
            <h2>Proprietatea intelectuală</h2>
            <p>Drepturile asupra Proprietăţii Intelectuale din acest website şi din materialele din acesta sau accesibile prin acesta aparţin Asociației Club Sportiv MTB Academy sau licenţelor sale. Website-ul, materialele de pe website sau cele accesibile prin acesta şi Drepturile asupra Proprietăţii Intelectuale inerente nu pot fi copiate, distribuite, publicate, licenţiate, folosite sau reproduse în niciun fel (în afară de măsura strict necesară pentru şi cu scopul legat de accesarea şi utilizarea acestui website).
            </p>
            <p>Asociația Club Sportiv MTB Academy şi logo-ul Asociației Club Sportiv MTB Academy aparţin Asociației Club Sportiv MTB Academy şi nu pot fi utilizate, copiate sau reproduse în niciun fel fără acordul scris al Asociației Club Sportiv MTB Academy.
            </p>
            <p>Pentru aceste scopuri “Drepturile asupra Proprietăţii Intelectuale” includ următoarele (oriunde şi oricând apar şi pentru întregul termen al acestora): orice drept, marcă înregistrată, nume înregistrat, nume de serviciu, design, drept asupra design-ului, copyright, drept asupra bazelor de date, drepturi morale, know how, secrete de muncă şi alte informaţii confidenţiale, drepturi de natura oricăror dintre aceste elemente în orice ţară, drepturi de natura competiţiei neloiale şi drepturi de a da în judecată pentru transmitere sau alte drepturi similare intelectuale şi comerciale (caz în care sunt sau nu înregistrate sau înregistrabile) şi înregistrările şi aplicaţiile de înregistrare pentru oricare dintre el.
            </p>
            <h2>Drepturile utilizatorilor</h2>
            <p>Utilizatorii acestui website au urmatoarele drepturi:
                <ul>
                    <li>1.	Dreptul de a nu fi de acord cu aceste termene si conditii, caz in care sunt obligati sa inceteze utilizarea acestui website.
                    </li>            <li>2.	Dreptul de a fi informat asupra datelor sale detinute de catre acest website
                    </li>            <li>3.	Dreptul de a solicita stergerea sau actualizarea datelor, daca ele sunt eronate. Datele care vor trebui pastrate din cauza legislatiei in vigoare nu vor putea fi sterse.
                    </li>            <li>4.	Dreptul de te opune anumitor prelucrari (comunicare comerciala, newsletter) sau de retragere a consimtamantului pentru anumite prelucrari.
                    </li></ul></p>
            <p>Pentru exercitarea acestor drepturi, utilizatorii se pot adresa: Asociației Club Sportiv MTB Academy la adresa de e-mail: mtbacademyromania[at]gmail.com sau telefonic la numerele: 0755122111
            </p>
            <p>Se va incerca rezolvarea oricarei dispute in legatura cu utilizarea Platformelor on-line si/sau a Aplicatiei si/sau Contractul mai intai pe cale amiabila. In cazul in care partile nu ajung la un acord cu privire la disputa, aceasta va fi definitiv solutionata de instanta romana competenta.
            </p>
            <p>Consumatorii nu pot renunta la drepturile lor conferite de lege. Orice clauze contractuale care inlatura sau limiteaza drepturile conferite de lege consumatorilor nu vor produce efecte cu privire la acestia.
            </p>
            <p>Totodata, utilizatorii au dreptul de a se adresa Autoritatii Nationale pentru Protectia Datelor cu Caracter personal sau altor autoritati in cazul in care considera ca trebuie sa isi apere oricare din drepturile garantate de legislatia in vigoare sau de noul regulament GDPR.
            </p>
            <p>Toate disputele, medierile vor fi guvernate de legea romana.
            </p>
            <h2>Prelucrarea datelor cu caracter personal</h2>
            <p>Prelucrarea datelor personale este efectuata atat in scopul furnizarii Serviciului cat si in scopul furnizarii unor informari si oricaror comunicari comerciale prin curier, posta, e-mail, telefon, fax sau alte mijloace, realizarea de rapoarte statistice, in scopul prevenirii/depistarii fraudelor etc.
            </p>Datele furnizate pentru utilizarea Serviciului sunt strict confidentiale, fiind utilizate exclusiv in cadrul executarii relatiei contractuale dintre abonati si Asociația Club Sportiv MTB Academy nefiind transmise catre terte persoane datele si informatiile colectate.
            <p>Datele personale pot fi transmise autoritatilor publice competente in vederea realizarii oricaror investigatii conform prevederilor legale in vigoare.
            </p>
            <p>Pentru utilizarea contului si a parolei personale intreaga responsabilitate le revine abonatilor, Asociația Club Sportiv MTB Academy fiind exonerata de orice raspundere cu privire la confidentialitatea acestor date. Prin inregistrare abonatilor le revine obligatia de a furniza in mod corect si complet datele solicitate.
            </p>
            <p>In conformitate cu prevederile Legii nr. 677/2001 pentrru protectia persoanelor cu privire la prelucrarea datelor cu caracter personal si libera circulatie a acestor date, clientii Asociației Club Sportiv MTB Academy beneficiaza de dreptul de acces la date, dreptul de interventie asupra datelor, dreptul de opozitie, dreptul la informare, dreptul de a nu fi supus unei decizii individuale si dreptul de a se adresa justitiei; toate aceste drepturi se exercita prin cerere scrisa, datata si semnata, inaintata Asociației Club Sportiv MTB Academy la sediul adresa de mail mentionata la rubrica de contact.
            </p>
            <h2>Limitarea răspunderii</h2>
            <p>Website-ul este distribuit pe baza “aşa cum este” şi “disponibil” fără nicio reprezentare sau promovare făcută şi fără garanţie de niciun fel expresă sau implicită, incluzând dar fără a se limita la garanţiile de calitate satisfăcătoare, fitness pentru un anumit scop, neîncălcare, compatibilitate, securitate şi acurateţe.
            </p>
            <p>În limita impusă de lege, Asociația Club Sportiv MTB Academy nu va fi trasă la răspundere pentru pierderi indirecte sau rezultate sau pentru pierderi de orice fel (incluzând dar nelimitându-se la pierderi de afaceri, de oportunităţi, de date, de profituri), ce rezultă din sau în legătură cu folosirea website-ului.
            </p>
            <p>Asociația Club Sportiv MTB Academy nu oferă nicio garanţie că funcţionarea website-ului va fi fără întrerupere sau fără erori, că defectele vor fi corectate sau că website-ul sau serverul care îl face disponibil sunt lipsite de viruşi sau orice altceva ce poate fi dăunător sau distructiv.
            </p>
            <h2>Despăgubiri</h2>
            <p>Sunteţi de acord să absolviţi Asociația Club Sportiv MTB Academy, angajaţii şi agenţii săi de toate răspunderile, taxele legale, stricăciunile, pierderile, costurile şi toate celelalte cheltuieli în relaţie cu revendicările sau acţiunile aduse împotriva Asociației Club Sportiv MTB Academy apărute din orice încălcare a Termenelor şi Condiţiilor de către dvs. sau alte responsabilităţi născute din utilizarea acestui website.
            </p>
            <h2>Anulare</h2>
            <p>În cazul în care oricare dintre prevederile acestei înţelegeri sunt declarate, de către orice autoritate juridică sau de o altă competenţă, nule, anulabile, ilegale sau nonexecutabile în vreun fel sau indicative de orice alt fel, ce sunt primite de dvs. sau de noi din partea unei autorităţi competente, vom modifica acea prevedere într-o manieră rezonabilă de aşa natură astfel încât să se conformeze intenţiilor părţilor fără a intra în ilegalitate sau, la discreţia noastră, prevederile în cauză pot fi scoase din această înţelegere, iar prevederile rămase în această înţelegere rămân în vigoare.
            </p>
            <h2>Legi aplicabile şi dispute</h2>
            <p>Această înţelegere şi toate cele ce rezultă din ea sunt guvernate de şi formulate în acord cu legea din România ale cărei curţi au jurisdicţie exclusivă asupra tuturor disputelor ce rezultă din această înţelegere, iar dvs. sunteţi de acord că locul de punere în practică al acestei înţelegeri este România.
            </p>
            <h2>Titluri</h2>
            <p>Titlurile sunt incluse în această înţelegere pentru convenienţă şi nu vor afecta înţelegerea acesteia.
            </p>
            <h2>Înţelegerea completă</h2>
            <p>Aceste termene şi aceste condiţii împreună cu alte documente la care se face referinţă expres în înţelegere includ întreaga înţelegere dintre noi în legătură cu subiectul exprimat şi înlocuiesc orice înţelegeri, aranjamente, angajamente sau propuneri anterioare, scrise sau orale: între noi şi acele aspecte. Orice explicaţie orală sau informare orală dată de vreuna dintre cele două părţi nu poate altera interpretarea termenelor şi a condiţiilor. Prin acceptarea termenelor şi condiţiilor nu v-aţi bazat pe o altă reprezentare decât cea stipulată în această înţelegere şi sunteţi de acord că nu veţi avea nicio cale de atac cu privire la orice falsă reprezentare ce nu a fost exprimată expres în această înţelegere.
            </p>
            <p>Utilizatorul este sfatuit sa verifice periodic prezentele Termene si Conditii.</p>




        </div>
    );
}

export default LegalTerms;