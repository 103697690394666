function getHour(IDODateAsString) {
    const d= new Date(IDODateAsString);
    return String(d.getHours()).padStart(2, '0')+':'+ String(d.getMinutes()).padStart(2, '0')
}
function getDate(IDODateAsString) {
  const d= new Date(IDODateAsString);  
  return String(d.getDate()).padStart(2, '0')+'-'+ String(d.getMonth()+1).padStart(2, '0')+'-'+ String(d.getYear()%100).padStart(2, '0')
}


function errorToText(error)
  {    
    if (!error) return 'Ups! Ceva nu a mers cum trebuie. Scuze!';
    if (error.response) {
      const data = error.response.data;
      const typeo = typeof data;
      const edata = Boolean(data);
      if (!edata) return 'Ups! Ceva nu a mers cum trebuie. Scuze!';
      if (edata && (typeo==="string")) {
          if  (data.includes('SequelizeForeignKeyConstraintError')) return 'Aceasta inregistrarea nu poate fi stearsa pentru ca alte inregistrari depind de ea!';
          if  (data.includes('SequelizeUniqueConstraintError')) return 'A aparut o duplicare a unui camp care trebuie sa aiba valori unice!';
          return data;
      }
      else {
        if (data.message)  {              
              if  (data.message.includes('SequelizeForeignKeyConstraintError')) return 'Aceasta inregistrarea nu poate fi stearsa pentru ca alte inregistrari depind de ea!';;
              if  (data.message.includes('SequelizeUniqueConstraintError')) return 'A aparut o duplicare a unui camp care trebuie sa aiba valori unice!';
              return data.message;              
        }       
        else 
          return "Am primit de la server eroarea asta:"+JSON.stringify(data);
      }     
    }
    return error?.message;
  }

  export {
    getHour,getDate,
    errorToText
  }