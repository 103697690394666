export const FETCH_ATHLETES_REQUEST = 'FETCH_ATHLETES_REQUEST'
export const FETCH_ATHLETES_SUCCESS = 'FETCH_ATHLETES_SUCCESS'
export const FETCH_ATHLETES_FAILURE = 'FETCH_ATHLETES_FAILURE'

export const SAVE_ATHLETE_REQUEST = 'SAVE_ATHLETE_REQUEST'
export const SAVE_ATHLETE_FAILURE = 'SAVE_ATHLETE_FAILURE'
export const SAVE_ATHLETE_SUCCESS = 'SAVE_ATHLETE_SUCCESS'

export const ADD_ATHLETE_REQUEST = 'ADD_ATHLETE_REQUEST'
export const ADD_ATHLETE_FAILURE = 'ADD_ATHLETE_FAILURE'
export const ADD_ATHLETE_SUCCESS = 'ADD_ATHLETE_SUCCESS'

export const DELETE_ATHLETE_REQUEST = 'DELETE_ATHLETE_REQUEST'
export const DELETE_ATHLETE_FAILURE = 'DELETE_ATHLETE_FAILURE'
export const DELETE_ATHLETE_SUCCESS = 'DELETE_ATHLETE_SUCCESS'

export const EDIT_ATHLETE_ACK = 'EDIT_ATHLETE_ACK'
