
import React, { useEffect,useState } from 'react';
import {useParams} from "react-router-dom"
import { useHistory } from 'react-router';
import { connect } from 'react-redux'
import { addAthlete, saveAthlete, fetchAthletes,editAthleteACK} from '../redux';
import { useAuth0 } from '@auth0/auth0-react';
import Menu from './menu';
import axios from 'axios';


function AthleteEditor(props) {
       
    const auth0 = useAuth0();
    const history = useHistory();
    const {user,isAuthenticated, isLoading,  loginWithRedirect, logout,getAccessTokenSilently}=auth0;   

    const [status,setStatus]=useState(''); 

    const [center,setCenter]=useState(''); 
    const [group,setGroup]=useState(''); 
    const [name,setName]=useState(''); 
    const [surname,setSurname]=useState(''); 
    const [CNP,setCNP]=useState(''); 
    const [nickname,setNickname]=useState(''); 
    const [birthdate,setBirthdate]=useState(''); 

    const [contactName,setContactName]=useState(''); 
    const [contactEmail,setContactEmail]=useState(''); 
    const [contactPhone,setContactPhone]=useState(''); 

    const [contact2Name,setContact2Name]=useState(''); 
    const [contact2Email,setContact2Email]=useState(''); 
    const [contact2Phone,setContact2Phone]=useState(''); 


    const [medicalInfo,setMedicalInfo]=useState(''); 
    const [bike,setBike]=useState(''); 
    const [uciNO,setUciNO]=useState(''); 
    const [uciExp,setUciExp]=useState(''); 

    const [lastError,setLastError]=useState(''); 
    


    const { id } = useParams(); 
    const [mode,setMode]=useState('necunoscut'); 
    const [athlete,setAthlete]=useState(props.athletes.find(x=>x.AthleteId===Number(id)));
    const [photo,setPhoto]=useState(undefined);
    //const [photoFile,setPhotoFile]=useState(null);

    const changePhoto=(ev)=>{
      const file = ev.target.files[0];      
      if (!file.type.startsWith('image/')){ return; }

      //setPhotoFile(ev.target.files[0])
      

      const img = document.getElementById("profile_photo");     
      img.file = file;
  
      const reader = new FileReader();
      reader.onload = (function(aImg) { return function(e) { aImg.src = e.target.result; setPhoto(reader.result) }; })(img);
      reader.readAsDataURL(file);

    }

    // const fileUploadHandler=()=> {
    //     const FD = new FormData();
    //     FD.append('photo',photoFile,photoFile.name);
    //     if (isAuthenticated && Boolean(user)) {
    //         getAccessTokenSilently()
    //         .then(token => {  
    //                         axios.post('http://localhost:8080/api/athletes/20/photo',FD,{
    //                             headers: {
    //                             Authorization: `Bearer ${token}`,
    //                             }
    //                         }
    //                         ).then(res=>console.log(res));
    //                     });
    //                 }
    // }
    
   const  validateCNP= (value)=> {
	var re = /^\d{1}\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])(0[1-9]|[1-4]\d| 5[0-2]|99)\d{4}$/,
		bigSum = 0,
		rest = 0,
		ctrlDigit = 0,
		control = '279146358279',
		i = 0;
	if (re.test(value)) {
		for (i = 0; i < 12; i++) {
			bigSum += value[i] * control[i];
		}
		ctrlDigit = bigSum % 11;
		if (ctrlDigit === 10) {
			ctrlDigit = 1;
		}

		if (ctrlDigit !== parseInt(value[12], 10)) {
			return false;
		} else {
			return true;
		}
	}
	return false;
   };

   const showError=(id,value)=>{
       // gasesc un element care are un anume id
       let e = document.getElementById(id);
       e.classList.add("input_error");
       console.log(e);
       let p=e.nextSibling;       
       p.firstChild.nodeValue = value;
       p.style = "display:block";
       // ii setez valoare value si il fac visibil (display block)

       return e;
   } 

   const hideError=(id)=>{
       let e = document.getElementById(id);
       e.classList.remove("input_error");

       e=e.nextSibling;       
       e.style = "display:none";
   }

   const extractBirthDate=(value)=>{
       if (value.length<7) return '';

       let year = Number(value.charAt(1)+value.charAt(2));
       if (year<50) year = year+2000; else year=1900+year;
       let month = Number(value.charAt(3)+value.charAt(4));
       let day = Number(value.charAt(5)+value.charAt(6));

       return year.toString()+'-'+month.toString().padStart(2,'0')+'-'+day.toString().padStart(2,'0');
   }

    useEffect(()=>{        
         if (!isAuthenticated || !Boolean(user) || !user.email_verified ) return;
        
         if (props.loading) return;

         if (!props.fetched) {
            getAccessTokenSilently()
            .then(token => {
                props.fetchAthletes(token);
            });
         }

         if (id==='addnew'){
            setMode('add');
            setCenter('Bucuresti');
            setContactEmail(user.email);
         } 
         else
         {
            const athlete = props.athletes.find(x=>x.AthleteId===Number(id));
            setAthlete(athlete);
            if (!Boolean(athlete)) { setMode('wrong'); return;}

            setMode('edit');
            setName(athlete.Name);
            setSurname(athlete.Surname);
            setCNP(athlete.CNP);
            setNickname(athlete.NickName);
            setCenter(athlete.Center)
            setGroup(athlete.Group)
            setBirthdate(athlete.BirthDate);
             
            setContactName(athlete.PrimaryContactName);
            setContactEmail(athlete.PrimaryContactEmail);
            setContactPhone(athlete.PrimaryContactPhone);

            setContact2Name(athlete.SecondaryContactName);
            setContact2Email(athlete.SecondaryContactEmail); 
            setContact2Phone(athlete.SecondaryContactPhone); 
            setMedicalInfo(athlete.MedicalInfo); 
            setBike(athlete.Bike);
            setUciNO(athlete.UCINo);
            setUciExp(athlete.UCILicenceExpiration);
            setStatus(athlete.Status);       
            setPhoto(athlete.photo);     
         }

    },[props.loading, user, isAuthenticated])

    const validari=()=>{
        // mandatory name,surname, cnp, birthdate
        let rez = true;
        let focusElement = null;
        if (!Boolean(name) || name ==='') {let e=showError('name','Numele este obligatoriu'); if (!focusElement) focusElement=e; rez=false;}
        if (!Boolean(surname) || surname ==='') { let e=showError('surname','Prenumele este obligatoriu'); if (!focusElement) focusElement=e; rez=false;}
        if (!Boolean(CNP) || CNP ==='') { let e=showError('cnp','CNP este obligatoriu'); if (!focusElement) focusElement=e; rez=false;}
        if (Boolean(CNP) && CNP !=='' && !validateCNP(CNP)) { let e=showError('cnp','CNP nu este Valid'); if (!focusElement) focusElement=e; rez=false;}
        if (!Boolean(birthdate) || birthdate ==='') { let e=showError('birthdate','Data nasterii este obligatorie'); if (!focusElement) focusElement=e; rez=false;}

        if (focusElement) focusElement.focus();
        return rez;
    }
     
    const handleSubmit=(event)=> {
        event.preventDefault();
        if (!validari()) return;

        const lathlete={
            AthleteId:mode==='add'?-1:Number(id),            
            Name:name,
            Surname:surname,
            CNP:CNP,
            NickName:nickname,
            Center:center,
            Group:group,
            CenterId:-1, // always use value in the center
            GroupId:mode==='add'?-1:athlete.GroupId,                       
            BirthDate:birthdate,
            MotherName:contactName,
            FatherName:contact2Name,
            PrimaryContactName:contactName,
            PrimaryContactPhone:contactPhone,
            PrimaryContactEmail:
               (contactEmail.includes(user.email) || contact2Email.includes(user.email))? contactEmail : contactEmail+', '+user.email
            ,
            SecondaryContactName:contact2Name,
            SecondaryContactPhone:contact2Phone,
            SecondaryContactEmail:contact2Email,
            OtherInfo:'',
            AnnouncementCanal:'primary',
            UCINo:uciNO,
            UCILicenceExpiration:uciExp,
            Bike:bike,
            MedicalInfo:medicalInfo,
            photo:photo
        }
        if (isAuthenticated && Boolean(user)) {
            getAccessTokenSilently()
            .then(token => {

                // console.log(lathlete);
                if (mode==='add') props.addAthlete(lathlete,token);
                else props.editAthlete(lathlete,token);      
            }
            ) 
        }                
    }

    const handleBack=(event)=>{
        history.push('/athletes');
    }

    if (!isAuthenticated || !Boolean(user) || !user.email_verified ) return (<Menu/>);

    if (props.loading||!props.fetched) return 'Loading...';
    else 
    if (props.loading_error!=='') return props.loading_error;
    else
    if ((mode==='add' || mode ==='edit'))
    return (    
        <>
        {(props.saving || props.saving_result_ack!=='none') &&
        <>
        <div id='backdrop'></div>
        <div id='popup'>
            {props.saving && 'Se trimite ....'}            
            {props.saving_result_ack==='error' && 
            <>
               <h2>Ups! Ceva nu  functionat</h2>
               <p>{props.saving_error}</p>

               <p>Poti corecta si apoi sa retrimiti datele!</p>

               <button className="btn primary-btn" onClick={()=>{setLastError(props.saving_error);  props.ack();}}>Ok</button>
            </>
            }
            {props.saving_result_ack==='success' && 
            <>
               <h2>Yey! </h2>
               <p>Totul a functionat!</p>
               <button className="btn primary-btn" onClick={()=>{props.ack();history.push('/athletes')}}>Ok</button>
            </>
            }

        </div>         
        </>
        }  

        <form >
            <h1>{mode==='add'?'Adauga sportiv':'Detalii sportiv'}</h1>
            
            {lastError!=='' &&
              <div id="error" style={{height:'auto'}}>
              <p >
                    {lastError}
              </p>
              </div>
            }

            <div><label htmlFor="stare">Stare</label><input readOnly={true} type="text" name="stare" id="stare" 
                      value={
                        status==='Rejected'?'Refuzat':
                        status==='Approved'?'Aprobat':
                        'Solicitare inscriere'
                    } /></div>
            

            <div><label htmlFor="center">Orasul*</label>
            <select name="center" id="center" value={center} onChange={(ev)=>{setCenter(ev.target.value)}} disabled={status==='Rejected'} >
                <option value="bucuresti">Bucuresti</option>
                <option value="brasov">Brasov</option>
                <option value="campulung">Campulung</option>
            </select>
            
            </div>
            <div><label htmlFor="name">Nume*</label>
                 <input readOnly={status==='Rejected'} type="text" name="name" id="name" value={name} onChange={(ev)=>{hideError('name'); setName(ev.target.value)}}/>
                 <p className="label_error" style={{display:'none'}}>eroare</p>
            </div>
            <div><label htmlFor="surname">Prenume*</label>
                 <input readOnly={status==='Rejected'} type="text" name="surname" id="surname" value={surname} onChange={(ev)=>{hideError('surname'); setSurname(ev.target.value)}} />
                 <p className="label_error" style={{display:'none'}}>eroare</p>
            </div>
            <div><label htmlFor="cnp">CNP*</label>
                 <input readOnly={status==='Rejected'} type="text" name="cnp" id="cnp" value={CNP} autoComplete={false}
                    onChange={(ev)=>{
                          if (validateCNP(ev.target.value))  {hideError('cnp'); setBirthdate(extractBirthDate(ev.target.value));}
                          else showError('cnp','CNP invalid'); 
                          setCNP(ev.target.value)}}/>
                 <p className="label_error" style={{display:'none'}}>eroare</p>
            </div>
            <div><label htmlFor="nickname">Porecla</label><input readOnly={status==='Rejected'} type="text" name="nickname" id="nickname" value={nickname} onChange={(ev)=>{setNickname(ev.target.value)}}/></div>
            <div><label htmlFor="birthdate">Data nasterii*</label>
                 <input readOnly={status==='Rejected'} type="date"  data-date-format="DD MMMM YYYY" name="birthdate" id="birthdate" value={birthdate} onChange={(ev)=>{hideError('birthdate'); setBirthdate(ev.target.value)}}/>
                 <p className="label_error" style={{display:'none'}}>eroare</p>
            </div>
            <div><label htmlFor="contactName">Mama</label><input readOnly={status==='Rejected'} type="text" name="contactName" id="contactName" value={contactName} onChange={(ev)=>{setContactName(ev.target.value)}}/></div>
            <div><label htmlFor="contactEmail">Email</label><input readOnly={status==='Rejected'} type="text" name="contactEmail" id="contactEmail" value={contactEmail} onChange={(ev)=>{setContactEmail(ev.target.value)}}/></div>
            <div><label htmlFor="contactPhone">Telefon</label><input readOnly={status==='Rejected'} type="text" name="contactPhone" id="contactPhone" value={contactPhone} onChange={(ev)=>{setContactPhone(ev.target.value)}}/></div>
            <div><label htmlFor="contact2Name">Tata</label><input readOnly={status==='Rejected'} type="text" name="contact2Name" id="contact2Name" value={contact2Name} onChange={(ev)=>{setContact2Name(ev.target.value)}}/></div>
            <div><label htmlFor="contact2Email">Email</label><input readOnly={status==='Rejected'} type="text" name="contact2Email" id="contact2Email" value={contact2Email} onChange={(ev)=>{setContact2Email(ev.target.value)}}/></div>
            <div><label htmlFor="contact2Phone">Telefon</label><input readOnly={status==='Rejected'} type="text" name="contact2Phone" id="contact2Phone" value={contact2Phone} onChange={(ev)=>{setContact2Phone(ev.target.value)}}/></div>
            <div><label htmlFor="medicalInfo">Info medicale</label><input readOnly={status==='Rejected'} type="text" name="medicalInfo" id="medicalInfo" value={medicalInfo} onChange={(ev)=>{setMedicalInfo(ev.target.value)}}/></div>
            <div><label htmlFor="bike">Bicicleta</label><input readOnly={status==='Rejected'} type="text" name="bike" id="bike" value={bike} onChange={(ev)=>{setBike(ev.target.value)}}/></div>
            <div><label htmlFor="uciNO">UCI No</label><input readOnly={status==='Rejected'} type="text" name="uciNO" id="uciNO" value={uciNO} onChange={(ev)=>{setUciNO(ev.target.value)}}/></div>
            <div><label htmlFor="uciExp">Data expirare UCI</label><input readOnly={status==='Rejected'} type="date" name="uciExp" id="uciExp" value={uciExp} onChange={(ev)=>{setUciExp(ev.target.value)}}/></div>

            <div className="input">
                <label className="photoLabel" htmlFor="photo">Photo</label>
                <img id="profile_photo"  src={photo}/>   
                <input readOnly={status==='Rejected'} type="file" name="photo" id="photo" onChange={(ev)=>{changePhoto(ev)}}></input>
            </div>
            {/* <button onClick={fileUploadHandler}>Upload</button> */}
            {/* react app    https://www.youtube.com/watch?v=XeiOnkEI7XI */}
            {/* backend/multer : https://www.youtube.com/watch?v=srPXMt1Q0nY */}


            {(!props.saving && status!=='Rejected') &&
            <button className="btn primary-btn" onClick={handleSubmit} >Trimite</button>              
            }

            <button className="btn secondary-btn" onClick={handleBack}>Inapoi</button>    
        </form>
        </>        
    )
    else     
    return 'Loading... mode=' +mode;
}

const mapStateToProps = state => {
    return {
      athletes: state.athletes.athletes,
      fetched: state.athletes.fetched,
      loading: state.athletes.loading,
      saving: state.athletes.saving,
      loading_error: state.athletes.loading_error,
      saving_error: state.athletes.saving_error,
      saving_result_ack : state.athletes.saving_result_ack,
    }
}

const mapDispatchToProps = dispatch => {
    return {
      addAthlete: (athlete,token) => dispatch(addAthlete(athlete,token)),
      editAthlete: (athlete,token)=> dispatch(saveAthlete(athlete,token)),
      ack: ()=> dispatch(editAthleteACK()),
      fetchAthletes: (token) => dispatch(fetchAthletes(token)),
    }
}
 
export default connect(
    mapStateToProps,mapDispatchToProps
  )(AthleteEditor);