import { useEffect, useState } from 'react'
import axios from 'axios'

export default function useTrainingHistorySearch(query, pageNumber, auth0) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [trainingHistories, setTrainingHistories] = useState([])
    const [hasMore, setHasMore] = useState(false)

    const { user, isAuthenticated, isLoading, loginWithRedirect, logout, getAccessTokenSilently } = auth0;

    useEffect(() => {
        setTrainingHistories([])
    }, [query])

    useEffect(async () => {
        setLoading(true)
        setError(false)

        let params = {};
        const pageSize = 3;
        params["spec.from"] = (pageNumber-1)*pageSize;
        params["spec.size"] = pageSize;
        params["TrainingStart.ord"] = 'desc';
        //params["TrainingStart.gte"] = '2022-08-01';
        params["spec.includeCount"] = true;
        let esc = encodeURIComponent;
        let queryParams = Object.keys(params)
            .map(k => esc(k) + '=' + esc(params[k]))
            .join('&');
        if (queryParams !== "") queryParams = '?' + queryParams;
        const SERVER = process.env.REACT_APP_BACKEND_URL;

        let cancel;

        if (!isAuthenticated || !Boolean(user) || !user.email_verified) return;
        if (isAuthenticated && Boolean(user)) {
            try {
                const token = await getAccessTokenSilently();
                const res = await axios.get(`${SERVER}/api/history${queryParams}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    cancelToken: new axios.CancelToken(c => cancel = c)
                });

                
                let data = res.data;
                const f = data.shift()                
                //console.log(JSON.stringify(meta))
                const count = f.meta["Count"];
                const retreived = trainingHistories.length + data.length;

                setTrainingHistories(prev => {                    
                    return [...prev, ...data]
                })
                
                console.log(count)
                console.log(retreived)

                setHasMore(count > retreived);
                setLoading(false);
            }
            catch (e) {
                if (axios.isCancel(e)) return
                setError(true)
            }
        }
        return () => cancel()
    }, [query, pageNumber,auth0 ]);

return { loading, error, trainingHistories, hasMore}
}