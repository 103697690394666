import { combineReducers } from 'redux'
import trainingsReducer from './trainings/trainings-reducer'
import profileReducer from './profile/profile-reducer'
import athletesReducer from './athletes/athletes-reducer'
import historyReducer from './history/history-reducer'

const rootReducer = combineReducers({
  trainings: trainingsReducer,
  profile: profileReducer,
  athletes:athletesReducer,
  history:historyReducer,
})

export default rootReducer