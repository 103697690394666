import axios from 'axios'
import { errorToText } from '../../helpers';
import {
  FETCH_TRAININGS_REQUEST,
  FETCH_TRAININGS_SUCCESS,
  FETCH_TRAININGS_FAILURE,
  SAVE_TRAINING_REQUEST,
  SAVE_TRAINING_FAILURE,
  SAVE_TRAINING_SUCCESS, 
} from './trainings-types'



export const fetchTrainings = (token) => {
  const SERVER =  process.env.REACT_APP_BACKEND_URL;
console.log('SERVER:',SERVER);
  return (dispatch) => {
    dispatch(fetchTrainingRequest());
    axios
      .get(`${SERVER}/api/today`,{
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then(response => {
        // response.data is the users
        const trainings = response.data
        dispatch(fetchTrainingSuccess(trainings))
      })
      .catch(error => {
        // error.message is the error message
        dispatch(fetchTrainingFailure(error.message))
      })
  }
}






export const saveConfirmation = (confirmation,token) => {
  const SERVER =  process.env.REACT_APP_BACKEND_URL;
  console.log('SERVER:',SERVER);
  return (dispatch) => {
    dispatch(saveTrainingRequest());
      
      axios
        .post(`${SERVER}/api/todayConfirmation`,confirmation, {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        })
        .then(response => {
          // simply success is enough? or api should return confirmation value ???        
          dispatch(saveTrainingSuccess(confirmation))
        })
        .catch(error => {
          // error.message is the error message
          dispatch(saveTrainingFailure(errorToText(error)))
        })
  }
}




export const saveTrainingSuccess = (confirmation) => {
  return {
    type: SAVE_TRAINING_SUCCESS,
    payload : confirmation
  }
}

export const saveTrainingRequest = () => {
  return {
    type:   SAVE_TRAINING_REQUEST,
  }
}
export const saveTrainingFailure = (error) => {
  return {
    type: SAVE_TRAINING_FAILURE,
    payload: error
  }
}


export const fetchTrainingRequest = () => {
  return {
    type: FETCH_TRAININGS_REQUEST
  }
}

export const fetchTrainingSuccess = trainings => {
  return {
    type: FETCH_TRAININGS_SUCCESS,
    payload: trainings
  }
}

export const fetchTrainingFailure = error => {
  return {
    type: FETCH_TRAININGS_FAILURE,
    payload: error
  }
}