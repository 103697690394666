import {
    FETCH_TRAININGS_REQUEST,
    FETCH_TRAININGS_SUCCESS,
    FETCH_TRAININGS_FAILURE,
    SAVE_TRAINING_REQUEST,
    SAVE_TRAINING_FAILURE,
    SAVE_TRAINING_SUCCESS,
  } from './trainings-types'
  
  const initialState = {
    fetched:false,
    loading: false,
    saving: false,
    trainings: [],
    loading_error: '',
    saving_error: ''
  }
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_TRAININGS_REQUEST:
        return {
          ...state,
          loading: true
        }
      case FETCH_TRAININGS_SUCCESS:
        return {
          loading: false,
          saving:false,
          trainings: action.payload,
          loading_error: '',
          saving_error:'',
          fetched:true
        }
      case FETCH_TRAININGS_FAILURE:
        return {
          loading: false,
          saving:false,
          trainings: [],
          loading_error: action.payload,
          saving_error:'',
          fetched:false
        }

      case SAVE_TRAINING_REQUEST:
        return {
          ...state,
          saving: true
      }
      case SAVE_TRAINING_FAILURE:
        return {
          ...state,
          saving: false,
          saving_error:action.payload,
      }
      case SAVE_TRAINING_SUCCESS:
        { 
        let changedTrainings=state.trainings;

        const trainingSessionId = action.payload.trainingSessionId;
        const athleteId = action.payload.athleteId;
        const confirmationValue = action.payload.confirmationValue;


        const c1 = changedTrainings.findIndex(x=>x.TrainingSessionId===trainingSessionId);
        if (c1===-1)  return state;
        const c2 = changedTrainings[c1].athletes.findIndex(x=>x.AthleteId===athleteId);
        if (c2===-1)  return state;

        changedTrainings[c1].athletes[c2].ConfirmationReceived=confirmationValue;

        return {
          ...state,
          loading:state.loading,
          trainings:changedTrainings,
          error:''
        }}

      default: return state
    }
  }
  
  export default reducer