import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux'

function Menu(props) {
    
    const auth0 = useAuth0();
    const history = useHistory();

    const {user,isAuthenticated, isLoading,  loginWithRedirect, logout}=auth0;    
    const login_handler=()=>loginWithRedirect();

    if (isLoading) { return <div>Loading...</div> }
    

    return (
        <div>                
        { (!isAuthenticated) &&
        <>

        <button className="btn secondary-btn" 
            onClick={login_handler}
                >Login</button>    
        <br/>
        <br/>
        <br/>
        <div><p style={{fontSize:'0.7em'}}> 
            Pentru probleme legate de functionarea aplicatiei <br/> solicitati ajutor la<br/>
             <a style={{fontSize:'1em'}} href="mailto:dan.giusca@gmail.com">dan.giusca@gmail.com</a> <br/> 
            <br/>  
            <br/>  
            <br/>  
                  
           </p> 
        </div>

        </>}

        { (isAuthenticated && user?.email_verified!==true ) &&
        <>
          Trebuie sa validezi email-ul. Cauta emailul de confirmare a contului si apasa pe linkul de acolo.           
          <button className="btn secondary-btn" 
            onClick={() => logout({
                returnTo: window.location.origin,
              })}
                >Logout</button>    
        </>}

        { (isAuthenticated && user?.email_verified===true ) &&
        <>
          { (props.athleteCount>0) &&
          <button className="btn secondary-btn" 
            onClick={() => history.push('/athletes')}
                >Sportivii mei ({props.athleteCount})
                </button>
          }

          { (props.athleteCount>0) &&
          <button className="btn secondary-btn" 
            onClick={() => history.push('/history')}
                >Istoric
                </button>
          }

          { (props.athleteCount===0) &&
          <button className="btn secondary-btn" 
            onClick={() => history.push('/athletes/addnew')}
                >Adauga ciclist
                </button>
          }

          <button className="btn secondary-btn" 
            onClick={() => logout({returnTo:window.location.origin})}
                >Logout</button>    
        </>}

        {/* <a className="suplimentar" href="https://app.mtbacademy.ro/backoffice">[backoffice app]</a> */}
        </div>
    );
}


const mapStateToProps = state => {
  return {
    athleteCount: state.profile?.profile?.roles?.filter(x=>x.role==='athlete').length
  }
}


export default connect(
  mapStateToProps
)(Menu);