import {
FETCH_ATHLETES_REQUEST,
FETCH_ATHLETES_SUCCESS,
FETCH_ATHLETES_FAILURE,

SAVE_ATHLETE_REQUEST,
SAVE_ATHLETE_FAILURE,
SAVE_ATHLETE_SUCCESS,

ADD_ATHLETE_REQUEST,
ADD_ATHLETE_FAILURE,
ADD_ATHLETE_SUCCESS,

DELETE_ATHLETE_REQUEST,
DELETE_ATHLETE_FAILURE,
DELETE_ATHLETE_SUCCESS,


EDIT_ATHLETE_ACK,

} from './athletes-types'


const initialState = {
    fetched:false,
    loading: false,
    saving: false,
    athletes: [],
    loading_error: '',
    saving_error: '',  
    saving_result_ack: 'none',/* error, success */  
  }
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ATHLETES_REQUEST:
        return {...state,loading: true,loading_error:''}
      case FETCH_ATHLETES_SUCCESS:
        return {...state, athletes:action.payload, loading:false, fetched:true}
      case FETCH_ATHLETES_FAILURE:
        return {...state,loading_error: action.payload, loading:false}
      case SAVE_ATHLETE_REQUEST:
        return {...state,saving: true,saving_error:''}
      case SAVE_ATHLETE_FAILURE:
        return {...state,saving: false,saving_result_ack:'error',saving_error:action.payload}
      case SAVE_ATHLETE_SUCCESS:
        {  
        const changedIndex =  state.athletes.findIndex(x=>x.AthleteId===action.payload.AthleteId);
        if (changedIndex<0) {
          console.error('AthleteId not found', state.athletes); 
          console.error(action.payload);
          return state;
          }
        let changed=state.athletes;
        changed[changedIndex]=action.payload;
        return {...state, saving:false, athletes:changed,saving_result_ack:'success'}  
        }
        
      case ADD_ATHLETE_REQUEST:
           return {...state,saving: true,saving_error:''}
      case ADD_ATHLETE_FAILURE:
            return {...state,saving: false,saving_result_ack:'error',saving_error:action.payload}
      case ADD_ATHLETE_SUCCESS:
          return {...state, athletes:[...state.athletes,action.payload],saving: false,saving_result_ack:'success'}

      case DELETE_ATHLETE_REQUEST:
           return {...state,saving: true,saving_error:''}
      case DELETE_ATHLETE_FAILURE:
           return {...state,saving: false,saving_error:action.payload}
      case DELETE_ATHLETE_SUCCESS:
          {
            const changedIndex =  state.athletes.findIndex(x=>x.AthleteId===action.payload.AthleteId);
            if (changedIndex<0) return state;
            let changed=state.athletes;
            changed.splice(changedIndex,1);   
            return {...state, athletes:changed,saving: false}
          }    
      case EDIT_ATHLETE_ACK:
        return {...state, saving_result_ack:'none',saving_error:''}


     
      default: return state;     
  }
}
  
export default reducer;

