  import {
    FETCH_PROFILE_REQUEST,
    FETCH_PROFILE_SUCCESS,
    FETCH_PROFILE_FAILURE,
    INIT_EMAIL
  } from './profile-types'
  
  const initialState = {
    fetched:false,
    loading: false,
    email:'',
    profile: {},
    loading_error: '',
  }
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_PROFILE_REQUEST:
        return {
          ...state,
          loading: true
        }
      case FETCH_PROFILE_SUCCESS:
        return {
          loading: false,
          profile: action.payload,
          email: state.email,
          loading_error: '',
          fetched:true
        }
      case FETCH_PROFILE_FAILURE:
        return {
          loading: false,
          profile: undefined,
          email: state.email,
          loading_error: action.payload,
          fetched:false
        }
      case INIT_EMAIL:
        return {
            ...state,
            email: action.payload,
        }
      
      default: return state
    }
  }
  
  export default reducer