import axios from 'axios'
import { errorToText } from '../../helpers';
import {
    FETCH_PROFILE_REQUEST,
    FETCH_PROFILE_SUCCESS,
    FETCH_PROFILE_FAILURE,
    INIT_EMAIL,  
} from './profile-types'



export const fetchProfile = (email,token) => {
  const SERVER =  process.env.REACT_APP_BACKEND_URL;
  console.log('SERVER:',SERVER);
  return (dispatch) => {
    dispatch(initEmail(email))  
    dispatch(fetchProfileRequest())
    axios
      .get(`${SERVER}/api/userprofiles/${email}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then(response => {
        // response.data is the users
        const profile = response.data
        dispatch(fetchProfileSuccess(profile))
      })
      .catch(error => {
        // error.message is the error message
        dispatch(fetchProfileFailure(error.message))
      })
  }
}





export const fetchProfileRequest = () => {
  return {
    type: FETCH_PROFILE_REQUEST
  }
}

export const fetchProfileSuccess = profile => {
  return {
    type: FETCH_PROFILE_SUCCESS,
    payload: profile
  }
}

export const fetchProfileFailure = error => {
  return {
    type: FETCH_PROFILE_FAILURE,
    payload: error
  }
}

export const initEmail = email => {
    return {
      type: INIT_EMAIL,
      payload: email
    }
  }