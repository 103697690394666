import { useRef } from "react"
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios'

export default function OPButton({ caption, athleteId, amount, trainingsessionId }) {
    const auth0context = useAuth0();
    const { user, isAuthenticated, getAccessTokenSilently } = auth0context;
    const container = useRef(null);


    const getServerData = async (athleteId, amount, trainingsessionId) => {
        const SERVER = process.env.REACT_APP_BACKEND_URL;
        const token = await getAccessTokenSilently();
        const res1 = await axios.post(`${SERVER}/api/online-payment`,
            {
                athleteId: athleteId,
                amount: amount,
                trainingsessions: [trainingsessionId],
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
        return res1.data;
    }

    const buildForm = (data) => {
        const GATEWAY = process.env.REACT_APP_GATEWAY_URL;
        const form = document.createElement('FORM');
        form.name = 'gateway';
        form.method = 'POST';
        form.target = "_self";
        form.action = GATEWAY;
        for (const key in data) {
            const tb = document.createElement('INPUT');
            tb.type = 'hidden';
            tb.name = key;
            tb.value = data[key];
            form.appendChild(tb);
        }

        container.current.appendChild(form);
        return form;
    }

    const addExtraData=(data)=> {

        data["ExtraData[silenturl]"]="https://app.mtbacademy.ro/api/online-payment-result"
        data["ExtraData[successurl]"]="https://app.mtbacademy.ro/api/online-payment-result"
        //data["ExtraData[failedurl]"]="https://app.mtbacademy.ro/history"
        data["ExtraData[backtosite]"]="https://app.mtbacademy.ro/api/online-payment-backtosite"
        data["ExtraData[backtosite_method]"]="GET"
        data["ExtraData[expireurl]"]="https://app.mtbacademy.ro/api/online-payment-backtosite"

        /*
            ExtraData	String	0-10240	Additional information sent by the mechant to the gateway. This data is echo back to the merchant in the reply request
            ExtraData[silenturl]	URL	1-255	Callback URL (server to server POST request)
            ExtraData[successurl]	URL	1-255	URL to redirect client to in case of successfull transaction
            ExtraData[failedurl]	URL	1-255	URL to redirect client to in case of failed transaction
            ExtraData[ep_target]	String	4	"self" for the case of using iframe
            ExtraData[ep_method]	String	4	"post" or "get" for succes/fail redirect
            ExtraData[backtosite]	URL	1-255	URL to redirect client to when clicking back to site button or after 60s on result page
            ExtraData[backtosite_method]	String	1-4	"post" or "get" for the back to site button
            ExtraData[expireurl]	String	1-255	If a timer is set on the payment page you can change the url that the client will be redirected to when the timer reach 0
            ExtraData[rate]	String	0-10	Installments number, format: [bank_code]-[installments_nr]
            ExtraData[filtru_rate]	String	0-300	Installments number, format: [bank_code1]-[i_nr1]-..-[i_nrN],[bank_code2]-[i_nr1]-..-[i_nr2]
            ex: bcr-3-4,btrl-5-6
            ExtraData[ep_channel]	String	0-100	Filter the available payment channels. Any combinations of the values CC, OP, C2P, MASTERPASS concatenated with comma ","
            generate_epid	String	1	If specified and have value 1, instead of redirecting the client will return a JSON containing the URL of the payment page
            valability	YYYYMMDDHHmmSS	14	Payment link available until the specified timestamp (Romania timezone) Need to be added in the fp_hash after the nonce param
            c2p_id	String	1-25	Unique ID of the client used for C2P wallet, blank values disable the wallet. Need to be added in the fp_hash after the nonce param (and after valability)
            c2p_cid	Numeric	1-12	Unique ID of the enroled cardc used for C2P wallet. Need to be added in the fp_hash after the c2p_id param.
            lang	String	2	Preselect the language of the payment page. Possible values: ro,en,fr,de,it,es,hu. If not sent the language will be chosen based on the client IP
            */

    }

    const onClickHandler = async () => {
        const data = await getServerData(athleteId, amount, trainingsessionId)

        addExtraData(data);


        const form = buildForm(data);
        form.submit();
    }
//        

    return (
        <div ref={container}>
            <button className="btn secondary-btn"
                onClick={onClickHandler}
            >{caption}</button>
        </div>
    )
}