import {
    FETCH_HISTORY_REQUEST_FIRSTPACK,
    FETCH_HISTORY_REQUEST_ALTPACK,
    FETCH_HISTORY_SUCCESS,
    FETCH_HISTORY_FAILURE
    } from './history-types'


    
const initialState = {
    fetching:false,
    count: -1,
    head:-1,
    records: [],
    filters:null,
    sort:null,
    packsize:0,
    error: ''
  }

const reducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_HISTORY_REQUEST_FIRSTPACK:
        return {...state}
      case FETCH_HISTORY_REQUEST_ALTPACK:
        return {...state}
      case FETCH_HISTORY_SUCCESS:
        return {...state, records:action.payload}
      case FETCH_HISTORY_FAILURE:
        return {...state,error:action.payload}
      
      default: return state;     
  }

}
  
export default reducer;