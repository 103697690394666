import axios from 'axios';
import { errorToText } from '../../helpers';
import {
    FETCH_ATHLETES_REQUEST,
    FETCH_ATHLETES_SUCCESS,
    FETCH_ATHLETES_FAILURE,
    
    SAVE_ATHLETE_REQUEST,
    SAVE_ATHLETE_FAILURE,
    SAVE_ATHLETE_SUCCESS,
    
    ADD_ATHLETE_REQUEST,
    ADD_ATHLETE_FAILURE,
    ADD_ATHLETE_SUCCESS,
    
    DELETE_ATHLETE_REQUEST,
    DELETE_ATHLETE_FAILURE,
    DELETE_ATHLETE_SUCCESS,

    EDIT_ATHLETE_ACK,

    } from './athletes-types'



  

    export const editAthleteACK = () => {
      return {
        type:EDIT_ATHLETE_ACK
    }
    }

    export const fetchAthletes = (token) =>  {
      const SERVER =  process.env.REACT_APP_BACKEND_URL;
      console.log('SERVER:',SERVER);
      return (dispatch) => {
        dispatch(fetchAthletesRequest());
        axios
        .get(`${SERVER}/api/today/athletes`,{
            headers: {
              Authorization: `Bearer ${token}`,
            }
          })
          .then(response => {
            // response.data is the users
            const athletes = response.data
            dispatch(fetchAthletesSuccess(athletes))
          })
          .catch(error => {
            // error.message is the error message
            dispatch(fetchAthletesFailure(errorToText(error)))
          })
     }
    }

    export const saveAthlete = (athlete, token) =>  {
      const SERVER =  process.env.REACT_APP_BACKEND_URL;
      console.log('SERVER:',SERVER);
      return (dispatch) => {  
        dispatch(saveAthleteRequest(athlete));
        axios
        .put(`${SERVER}/api/athletes/${athlete.AthleteId}`,athlete,{
            headers: {
              Authorization: `Bearer ${token}`,
            }
          })
          .then(response => {
            // response.data is the users
            //const athlete = response.data
            dispatch(saveAthleteSuccess(athlete))
          })
          .catch(error => {
            // error.message is the error message
            dispatch(saveAthleteFailure(errorToText(error)))
          })
     }
    }

    export const addAthlete = (athlete, token) =>  {
      const SERVER =  process.env.REACT_APP_BACKEND_URL;
      console.log('SERVER:',SERVER);
      return (dispatch) => {  
        dispatch(addAthleteRequest(athlete));
        axios
        .post(`${SERVER}/api/athletes`,athlete,{
            headers: {
              Authorization: `Bearer ${token}`,
            }
          })
          .then(response => {
            // response.data is the users
            const athlete = response.data
            dispatch(addAthleteSuccess(athlete))
          })
          .catch(error => {
            // error.message is the error message
            dispatch(addAthleteFailure(errorToText(error)))
          })
      }
    }

    export const deleteAthlete = (athlete, token) =>  {
      const SERVER =  process.env.REACT_APP_BACKEND_URL;
      console.log('SERVER:',SERVER);
      return (dispatch) => {    
        dispatch(deleteAthleteRequest(athlete));
        axios
        .delete(`${SERVER}/api/athletes/${athlete.AthleteId}`,athlete,{
            headers: {
              Authorization: `Bearer ${token}`,
            }
          })
          .then(response => {
            // response.data is the users
            const athlete = response.data
            dispatch(deleteAthleteSuccess(athlete))
          })
          .catch(error => {
            // error.message is the error message
            dispatch(deleteAthleteFailure(errorToText(error)))
          })
      }
    }


    export const fetchAthletesRequest = ()=>{
        return {
            type:FETCH_ATHLETES_REQUEST
        }
    }
    export const fetchAthletesSuccess = (athletes)=>{
        return {
            type:FETCH_ATHLETES_SUCCESS,
            payload:athletes
        }
    }
    export const fetchAthletesFailure = (error)=>{
        return {
            type:FETCH_ATHLETES_FAILURE,
            payload:error
        }
    }

    export const saveAthleteRequest = (athlete)=>{
        return {
            type:SAVE_ATHLETE_REQUEST,
            payload:athlete
        }
    }
    export const saveAthleteSuccess = (athlete)=>{
        return {
            type:SAVE_ATHLETE_SUCCESS,
            payload:athlete
        }
    }
    export const saveAthleteFailure = (error)=>{
        return {
            type:SAVE_ATHLETE_FAILURE,
            payload:error
        }
    }


    export const addAthleteRequest = (athlete)=>{
        return {
            type:ADD_ATHLETE_REQUEST,
            payload:athlete
        }
    }
    export const addAthleteSuccess = (athlete)=>{
        return {
            type:ADD_ATHLETE_SUCCESS,
            payload:athlete
        }
    }
    export const addAthleteFailure = (error)=>{
        return {
            type:ADD_ATHLETE_FAILURE,
            payload:error
        }
    }

    
    export const deleteAthleteRequest = (athlete)=>{
        return {
            type:DELETE_ATHLETE_REQUEST,
            payload:athlete
        }
    }
    export const deleteAthleteSuccess = (athlete)=>{
        return {
            type:DELETE_ATHLETE_SUCCESS,
            payload:athlete
        }
    }
    export const deleteAthleteFailure = (error)=>{
        return {
            type:DELETE_ATHLETE_FAILURE,
            payload:error
        }
    }
   
