import React from 'react'
import { Provider } from 'react-redux'
import './App.css';
import store from './redux/store'
import {
  BrowserRouter,
  Switch,
  Route,
} from "react-router-dom";

import BigAppBar from './components/bigappbar';
import SmallAppBar from './components/smallappbar';
import AthleteList from './components/athletelist';
import TrainingHistory from './components/TrainingHistory';

import LegalTerms from './components/legalterms';
import LegalPrivacy from './components/legalprivacy';

import AthleteEditor from './components/athleteeditor';
import Today from './components/today';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import Contact from './components/contact';
import Footer from './components/footer';



function App() {

  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

 
  return (
    <Provider store={store}>
      <BrowserRouter basename={'/'}>
        <Auth0Provider
          domain={domain}
          clientId={clientId}
          audience={audience}
          redirectUri={window.location.origin}
        //onRedirectCallback={(appState) => { history.push(appState?.returnTo || window.location.origin);}}      
        >
          <Switch>
            <Route exact path="/">
              <BigAppBar />
              <main><Today /></main>
            </Route>
            <Route exact path="/history">
              <SmallAppBar />
              <main><TrainingHistory /></main>
            </Route>
            <Route exact path="/athletes">
              <SmallAppBar />
              <main><AthleteList /></main>
            </Route>
            <Route exact path="/athletes/:id">
              <SmallAppBar />
              <main><AthleteEditor /></main>
            </Route>
            <Route exact path="/legal/terms">
              <SmallAppBar />
              <main><LegalTerms /></main>
            </Route>
            <Route exact path="/legal/contact">
              <SmallAppBar />
              <main><Contact /></main>
            </Route>

          </Switch>

          <footer>  
              <Footer/>
          </footer>
        </Auth0Provider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
