import React, { useRef, useCallback, useState, useEffect } from 'react';
import { getHour, getDate } from '../helpers'
import Menu from './menu';
import { useHistory } from 'react-router';
import useTrainingHistorySearch from '../hooks/useTrainingHistorySearch'
import { useAuth0 } from '@auth0/auth0-react';
import OPButton from './op-button';



function TrainingHistory(props) {

    const [query, setQuery] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const history = useHistory();
    const auth0context = useAuth0();
    const { user, isAuthenticated, getAccessTokenSilently } = auth0context;

    useEffect(() => {
        if (!isAuthenticated || !Boolean(user) || !user.email_verified) return;
        if (isAuthenticated && Boolean(user)) {
            setQuery('')
            setPageNumber(1)
        }
    }, [isAuthenticated, user])

    const {
        trainingHistories,
        hasMore,
        loading,
        error,
    } = useTrainingHistorySearch(query, pageNumber, auth0context)

    const observer = useRef()
    const lastRecordRef = useCallback(node => {
        if (loading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPageNumber(prevPageNumber => prevPageNumber + 1)
            }
        })
        if (node) observer.current.observe(node)
    }, [loading, hasMore])


    function handleSearch(e) {
        setQuery(e.target.value)
        setPageNumber(1)
    }


    if (!isAuthenticated || !Boolean(user) || (user === undefined) || !user.email_verified) {
        return (<Menu />);
    }

    return (
        <div>

            <button className="btn secondary-btn"
                onClick={() => history.push('/')}
            >Inapoi</button>




            <h1>Istoric (plata online in testare)</h1>
            <table>
                <thead>
                    <tr>
                        <th>Data</th><th>Sportiv</th><th>Locatie</th><th>Prez.</th>
                        <th>Cost</th>
                        <th>Stare</th>
                    </tr>
                </thead>
                <tbody>

                    {



                        trainingHistories.map((item, index) => {
                            if (trainingHistories.length === index + 1) {
                                return (
                                    <tr ref={lastRecordRef} key={item.trainingsessionxathleteid}>
                                        <td>{getDate(item.TrainingStart)}<br />{getHour(item.TrainingStart)}</td>
                                        <td>{item.Name}</td>
                                        <td>{item.TrainingLocationText}</td>
                                        <td>{item.PresenceConfirmed ? 'P' : 'A'}</td>
                                        <td> {item.stare === 'abonament' ? '-' : item.cost}</td>
                                        <td>{(item.stare === 'ne-platit') ?
                                            ( item.TrainingStart<'2022-08-01'?'(achitat)':
                                             <OPButton caption='Plateste'
                                                athleteId={item.athleteId}
                                                amount={item.cost}
                                                trainingsessionId={item.trainingsessionId} /> ): item.stare}</td>
                                    </tr>
                                )
                            } else {
                                return (
                                    <tr key={item.trainingsessionxathleteid}>
                                        <td>{getDate(item.TrainingStart)}<br />{getHour(item.TrainingStart)}</td>
                                        <td>{item.Name}</td>
                                        <td>{item.TrainingLocationText}</td>
                                        <td>{item.PresenceConfirmed ? 'P' : 'A'}</td>
                                        <td> {item.stare === 'abonament' ? '-' : item.cost}</td>
                                        <td>{(item.stare === 'ne-platit') ?
                                            (item.TrainingStart<'2022-08-01'?'(achitat)':
                                            <OPButton caption='Plateste'
                                                athleteId={item.athleteId}
                                                amount={item.cost}
                                                trainingsessionId={item.trainingsessionId} />) : item.stare}</td>
                                    </tr>
                                )
                            }

                        }
                        )
                    }
                </tbody>
            </table>
        </div>
    );
}


export default TrainingHistory;