import React from 'react';


function Contact(props) {

    
    return (
        <div style={{ textAlignLast: 'left', textIndent: 0, textAlign: 'left' }}>
            <h1> Datele de contact</h1>
            
            Asociatia Mtb Academy<br/>
            CUI 34299340<br/>
            Sos Iancului, 27 a, bl 105d, sc A, ap 38<br/>
            Cont bancar asociatie:
            RO28 INGB 0000 9999 0497 9805<br/>

            persona de contact: Razvan Juganaru<br/>
            email : mtbacademyromania@gmail.com<br/>
            telefon : 0755122111

        </div>
    );
}

export default Contact;