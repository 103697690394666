import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

function BigAppBar(props) {
    const auth0 = useAuth0();
    return (
        <header>    
             {
                 auth0.isAuthenticated && (
                    <div>
                      <p>{auth0.user.email}</p>
                    </div>)
            }    
        </header>  
    );
}

export default BigAppBar;