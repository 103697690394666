import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router';
import { connect } from 'react-redux'
import { fetchAthletes } from '../redux'
import Menu from './menu';


function AthleteList(props) {

    const auth0 = useAuth0();
    const history = useHistory();
    const {user,isAuthenticated, isLoading,  loginWithRedirect, logout,getAccessTokenSilently}=auth0;    


    useEffect(()=>{  
        if (!isAuthenticated || !Boolean(user) || !user.email_verified ) return;  
        if (isAuthenticated && Boolean(user)) {
           getAccessTokenSilently()
           .then(token => {
               props.fetchAthletes(token);
           }
           ) ;           
        }
     },[isAuthenticated, user])

     if (!isAuthenticated || !Boolean(user) || !user.email_verified ) return (<Menu/>);

    return (
        <div>            
            <h1>Sportivi</h1>
            {props.athletes.map(athlete=><div className='athleteCard' key={athlete.AthleteId}>
                   <p> {athlete.Name} {athlete.Surname} </p>
                   <p> Grupa:{athlete.Group} Centrul:{athlete.Center} </p> 
                   <p> Stare:{
                        athlete.Status==='Requested'?'Solicitare inscriere':
                        (athlete.Status==='Rejected'?'Refuzat':
                        (athlete.Status==='Approved'?'Aprobat':
                        ('In curs de stabilire'
                        )))
                   }</p>
                   {/*JSON.stringify(athlete)*/}
                   <button className="btn secondary-btn" 
                    onClick={() => history.push(`/athletes/${athlete.AthleteId}`)}
                    >Detalii</button>    
                 </div>
            )} 


            <button className="btn primary-btn" 
            onClick={() => history.push('/athletes/addnew')}
                >Adauga</button>    

            <button className="btn secondary-btn" 
            onClick={() => history.push('/')}
                >Inapoi</button>    
        </div>
    );
}

const mapStateToProps = state => {
    return {
      athletes: state.athletes.athletes
    }
}

const mapDispatchToProps = dispatch => {
    return {
      fetchAthletes: (token) => dispatch(fetchAthletes(token)),
    }
}

  
export default connect(
    mapStateToProps,mapDispatchToProps
  )(AthleteList);